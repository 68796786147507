/* global SD, GeoPoint, SDGeocode, map */
import * as React from "react";
import { useState, useRef, useEffect } from 'react'

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import Layout from "../components/layout";
import Editor from "../components/Editor"
import Map from "../components/SDMap";
import Ad from '../components/Ad'

const Container = styled(Box)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up("lg")]: {
        flexDirection: 'column',
    }
}));

const NotSupported = styled(Box)(({ theme }) => ({
    '@media (min-width: 0px)': {
        display: 'block'
    },
    '@media (min-width: 1200px)': {
        display: 'none'
    }
}));




function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

const MoreButton = ({ onUp, onDown }) => {
    return <><Button
        size="small"
        variant="contained"
        onClick={() => onUp(1)}
    >Zoom+</Button>
        <Button
            size="small"
            variant="contained"
            onClick={() => onDown(-1)}
        >Zoom-</Button>
    </>
}



const IndexPage = ({ data, location }) => {
    const params = parseQuery(location.search)
    const hideLogo = params.hideLogo;
    const divRef = useRef(null)
    const [query, setQuery] = useState("")
    const [geocode, setGeocode] = useState({
        lat: 103.80733282853,
        lng: 1.289478890541
    })
    const [map, setMap] = useState(null)
    useEffect(() => {
        window.set_data = (json) => {
            if (json[0]) {
                setGeocode({
                    lat: json[0].x,
                    lng: json[0].y,
                })
            }
        }
    }, [])

    const findGeo = (q) => {

        var geocode = new SDGeocode(map);
        var searchOption = { "q": q };
        var gc = SDGeocode.SG;
        geocode.requestData(gc, searchOption)
    }

    return (
        <Layout fullWidth={true}>
            <Container>
                <Ad />
            </Container>
            <Box display="flex" alignItems={"center"} justifyContent={"center"} flexDirection={"column"}>
                <Box alignSelf={"center"} justifyContent={"center"}>
                    <Box display="flex" flexDirection="row" mb="16px">
                        <TextField id="outlined-basic" label="Postal Code" variant="outlined"
                            onChange={e => setQuery(e.target.value)}
                            value={query}
                        />
                        <Button
                            size="small"
                            onClick={() => findGeo(query)}
                        >Find</Button>

                    </Box>
                </Box>
                <Box maxWidth={"1200px"} width="100%">
                <Map
                            mapRef={map => {
                                map.refresh()
                                var latlng = new GeoPoint(103.80733282853, 1.289478890541);
                                map.setCenter(latlng, map.zoom)
                                setMap(map)
                            }}
                            centerGeocode={geocode}
                        />

                    <Editor

                        moreAction={<MoreButton 
                            onUp={v => map.setZoom(map.zoom + v)}
                            onDown={v => map.setZoom(map.zoom + v)}
                        />}
                    >
                        <Map
                            mapRef={map => {
                                var latlng = new GeoPoint(geocode.lat, geocode.lng);
                                map.setCenter(latlng, map.zoom)
                                setMap(map)
                            }}
                            centerGeocode={geocode}
                        />

                    </Editor>
                </Box >
            </Box>
        </Layout>)
}

export default IndexPage
