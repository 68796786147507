/* global SD, GeoPoint, SDGeocode, map */
import * as React from "react";
import Box from '@mui/material/Box';

const { useEffect, useRef } = React;

const LENGTH = 1200

const IndexPage = ({ onMapChange, mapRef }) => {
    const divRef = useRef(null)
    const loadMap = () => {

        window.set_data = (json) => {
            if (json[0]) {
                onMapChange && onMapChange({
                    lat: json[0].x,
                    lng: json[0].y,
                })
            }
        }

        var latlng = new GeoPoint(103.80733282853, 1.289478890541);
        const myOptions = {
            zoom: 13,
            center: latlng,
            showCopyright: false
        };

        const map = new SD.genmap.Map(
            divRef.current,
            myOptions
        );
        map.setCenter(latlng, map.zoom)
        mapRef(map)
    }

    useEffect(() => {
        loadMap()

    }, [])


    return (
        <Box style={{ position: 'relative', width: `${LENGTH}px`, height: `${LENGTH}px`, }}>
            <div ref={divRef} style={{ width: `100%`, height: `100%` }}></div>
        </Box>)
}

export default IndexPage
